import {
  type DoctorCRMRegisterStatus,
  type DoctorCRMRegisterType,
  ProfessionalStatus,
  type ProfessionalsStatusesCountData,
  WorkflowStatus,
} from '@medsimples/doctor-onboarding-openapi-v2';
import { Poppins } from 'next/font/google';
import schemes from '../styles/colorSchemes';

export type StatusLabel = keyof ProfessionalsStatusesCountData;

export const statusName: Partial<Record<StatusLabel, string>> = {
  ALL: 'Todos',
  [ProfessionalStatus.REGISTERING]: 'Em cadastro',
  [WorkflowStatus.ACTION_PENDING]: 'Em recadastro',
  [ProfessionalStatus.WAITING_APPROVAL]: 'Em aprovação',
  [ProfessionalStatus.WAITING_COMPLIANCE]: 'Compliance',
  [ProfessionalStatus.WAITING_SYNCHRONIZATION]: 'Aprovado',
  [ProfessionalStatus.ENABLED]: 'Ativo',
  [ProfessionalStatus.DISABLED]: 'Reprovado',
  [ProfessionalStatus.WAITING_BIOMETRICS]: 'Aguardando biometria',
  [ProfessionalStatus.WAITING_PRE_APPROVAL]: 'Em pré-aprovação',
  [ProfessionalStatus.PRE_APPROVED]: 'Pré-aprovado',
};

export const preApprovalStatusName: Partial<Record<StatusLabel, string>> = {
  ALL: 'Todos',
  [ProfessionalStatus.REGISTERING]: 'Em cadastro',
  [WorkflowStatus.ACTION_PENDING]: 'Em recadastro',
  [ProfessionalStatus.WAITING_PRE_APPROVAL]: 'Em pré aprovação',
  [ProfessionalStatus.PRE_APPROVED]: 'Pré aprovado',
  [ProfessionalStatus.DISABLED]: 'Reprovado',
};

export const providerLogoSizes = {
  amil: '15px',
  americas: '15px',
  open: '30px',
};

export const disabledFieldProps = {
  background: schemes.medsimples.colors.gray[50],
  opacity: 1,
};

export const poppins = Poppins({
  weight: ['400', '500', '600'],
  subsets: ['latin'],
});

export const chakraThemeProps = {
  colorPrimary: '#00317E',
  colorPrimaryHover: '#024cbf',
  fontSize: 16,
  fontFamily: poppins.style.fontFamily,
  controlInteractiveSize: 20,
};

export type ApprovalType =
  | 'approve'
  | 'return'
  | 'deny'
  | 'pre-approve'
  | 'pre-deny';

export enum TabsIndexes {
  PERSONAL_DATA = 0,
  SPECIALTIES = 1,
  PROFESSIONAL_DOCUMENTS = 2,
  COMPANY_DATA = 3,
  COMPANY_DOCUMENTS = 4,
  COMPLIANCE_QUESTIONS = 5,
  BACKGROUND = 6,
  APPROVAL_HISTORY = 7,
  COMMENTS = 8,
}

export enum ActionButtons {
  APPROVE = 'APPROVE',
  DENY = 'DENY',
  RETURN_TO_GTA = 'RETURN_TO_GTA',
  SEND_TAX_FORM = 'SEND_TAX_FORM',
  PRE_APPROVE = 'PRE_APPROVE',
  PRE_DENY = 'PRE_DENY',
}

export const DoctorCRMRegisterTypeLabels: Record<
  DoctorCRMRegisterType,
  string
> = {
  MAIN: 'Principal',
  SECONDARY: 'Secundário',
  FOREIGNER_STUDENT: 'Estudante Estrangeiro',
  TEMPORARY: 'Temporário',
  UNINFORMED: 'Não informado',
};

export const DoctorCRMRegisterStatusLabels: Record<
  DoctorCRMRegisterStatus,
  string
> = {
  DEAD: 'Falecido',
  TRANSFERRED: 'Transferido',
  CANCELLED: 'Cancelado',
  RETIRED: 'Aposentado',
  ACTIVE: 'Ativo',
  REVOKED: 'Cassado',
  NO_WORK: 'Sem o exercício da profissão na UF',
  TEMPORARY_SUSPENSION: 'Suspensão temporária',
  TOTAL_TEMPORARY_SUSPENSION: 'Suspensão total temporária',
  TOTAL_PRECAUTIONARY_INTERDICTION: 'Interdição cautelar - total',
  PARTIAL_PRECAUTIONARY_INTERDICTION: 'Interdição cautelar - parcial',
  TOTAL_JURIDIC_SUSPENSION: 'Suspenso por ordem judicial - total',
  PARTIAL_JURIDIC_SUSPENSION: 'Suspenso por ordem judicial - parcial',
  SUBSCRIPTION_CANCELLED: 'Inscrição anulada',
  NON_OPERATIONAL: 'Inoperante',
  SUSPENDED_TOTAL: 'Suspenso - total',
  SUSPENDED_PARTIAL: 'Suspenso - parcial',
  INVALID_VALUE: '(Valor inválido para situação)',
};

export const themes = {
  antecipame: 'antecipameTheme',
  default: 'medsimplesTheme',
};
